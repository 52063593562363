import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Dialog, {DialogProps} from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CardActionArea from '@mui/material/CardActionArea';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Title} from "./Onken21OperaEvent";

interface MainProps {
    title: string;
}

const titleStyle = {color: "#007200", fontWeight: 'normal', fontSize: 16};
const noteStyle = {color: "#ff69b4", fontWeight: 'normal', fontSize: 20};

const messageStyle = {color: "#007200", fontWeight: 'bold', fontSize: 18};
const message1 = '音研21ではオペラ出演者を募集しております。';
const message2 = 'お問い合わせ：03-3920-2144（オフィス ヨコタ内）';
const message3 = '　　　　　　：onken21.web.master@gmail.com';
//const message4 = '次回公演予定：';

const updateMessageStyle = {color: "#000000", fontWeight: 'normal', fontSize: 16};
const updateMessage = [
    '　✳️ 2024年8月4日（日）更新',
    '　✳️ 2023年8月26日（土）更新',
    '　✳️ 2023年6月17日（土）更新',
    '　✳️ 2022年11月3日（木）更新',
    '　✳️ 2022年9月22日（木）🎊 新装開店 🎉'
];

export default function MainContent(props: MainProps) {
    const {title} = props;
    const [fullWidth, /*setFullWidth*/] = React.useState(true);
    const [maxWidth, /*setMaxWidth*/] = React.useState<DialogProps['maxWidth']>('lg');
    const [open, setOpen] = React.useState(false);
    const [windowDimension, /*detectHW*/] = React.useState({
        winWidth: window.innerWidth, winHeight: window.innerHeight,
    })
    const imageMaxHeight = windowDimension.winHeight * 0.75;
    const onClickEndHandler = () => {
        handleClickOpen();
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid item xs={12} md={8} sx={{'& .markdown': {py: 3}}}>

            <CardActionArea component="a" href="#" onClick={onClickEndHandler} sx={{height: 50}}>
                <Card sx={{display: 'flex', boxShadow: 2, height: 44, ml: 2, mr: 2, bgcolor: '#FFFFFF60'}}>
                    <CardContent sx={{flex: 1, p: 1}}>
                        <Title>
                            <span style={titleStyle}><span style={noteStyle}>♫</span> オペラ出演者募集</span>
                        </Title>
                    </CardContent>
                </Card>
            </CardActionArea>

            <Typography variant="h6" gutterBottom sx={{mt: 2}}>
                {title}
            </Typography>

            <Divider sx={{mb: 1}}/>

            {updateMessage.map((message, index) => (
                <Typography variant="subtitle1" style={updateMessageStyle}>
                    {message}
                </Typography>
            ))}

            {/* large image modal dialog */}
            <div>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent sx={{p: 1, m: 1}}>
                        <Box sx={{margin: 'auto', pr: 1, width: 'fit-content'}}>
                            {/* iPhone 12 Pro Max=620, iPhone7=420 */}

                            <Typography variant="h6" sx={{mt: 3, mb: 3}} style={messageStyle}>
                                {message1}
                            </Typography>

                            <img
                                style={{maxHeight: imageMaxHeight/*425*/, border: '1px #00720050 solid', padding: 10}}
                                src={"./old_contents/RecruitmentOfPerformers.jpg"}
                                alt={'no image'}
                                loading="lazy"
                            />

                            <Typography variant="h6" sx={{mt: 5}} style={messageStyle}>
                                {message2}
                            </Typography>
                            <Typography variant="h6" sx={{mb: 1}} style={messageStyle}>
                                {message3}
                            </Typography>

                        </Box>
                    </DialogContent>
                    <DialogActions sx={{p: 0}}>
                        <Button autoFocus onClick={handleClose}>閉じる</Button>
                    </DialogActions>
                </Dialog>
            </div>

        </Grid>
    );
}
