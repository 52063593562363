export const EventType = {
    Opera: 'opera',
    Operetta: 'operetta',
    Concert: 'concert'
} as const;

export type EventType = typeof EventType[keyof typeof EventType]; // 'opera' | 'operetta' | 'concert'

export interface PastEventIndexProps {
    description: string;
    title: string;
    archives: ReadonlyArray<{
        event_id: string;
        event_date: string;
        title: string;
        place: string;
        event_type: EventType;
        url: string;
    }>;
}

export const past_events: PastEventIndexProps = {
    description: '今までの活動・公演',
    title: '',
    archives: [
        {
            event_id: '20231112',
            event_date: '2023-11-12',
            title: 'ジョイフルコンサート',
            place: '（2023 京都コンサートホール）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20230923',
            event_date: '2023-09-23',
            title: 'ジョイフルコンサート',
            place: '（2023 ルネこだいら）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20220910',
            event_date: '2022-09-10',
            title: 'ジョイフルコンサート',
            place: '（2022 京都市北文化会館ホール）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20220416',
            event_date: '2022-04-16',
            title: 'ジョイフルコンサート',
            place: '（2022 ルネこだいら）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20191026',
            event_date: '2019-10-26',
            title: 'ジョイフルコンサート',
            place: '（2022 ルネこだいら）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20190427',
            event_date: '2019-04-27',
            title: 'パリアッチ',
            place: '（2019 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20180414',
            event_date: '2018-04-14',
            title: 'メリー・ウィドウ',
            place: '（2018 ルネこだいら）',
            event_type: EventType.Operetta,
            url: '#',
        },
        {
            event_id: '20171029',
            event_date: '2017-10-29',
            title: 'ジョイフルコンサート',
            place: '（2017 杉並区立勤労福祉会館）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20170521',
            event_date: '2017-05-21',
            title: 'カルメン',
            place: '（2017 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20170513',
            event_date: '2017-05-13',
            title: 'カルメン',
            place: '（2017 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20161029',
            event_date: '2016-10-29',
            title: 'ジョイフルコンサート',
            place: '（2016 杉並区立勤労福祉会館）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20150418',
            event_date: '2015-04-18',
            title: 'トスカ',
            place: '（2015 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20150411',
            event_date: '2015-04-11',
            title: 'トスカ',
            place: '（2015 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20140419',
            event_date: '2014-04-19',
            title: '椿姫',
            place: '（2014 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20140412',
            event_date: '2014-04-12',
            title: '椿姫',
            place: '（2014 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20130317',
            event_date: '2013-03-17',
            title: 'スプリングコンサート',
            place: '（2013 ルネこだいら）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20120324',
            event_date: '2012-03-24',
            title: 'ラ・ボエーム',
            place: '（2012 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20120318',
            event_date: '2012-03-18',
            title: 'ラ・ボエーム',
            place: '（2012 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20111029',
            event_date: '2011-10-29',
            title: 'ジョイフルコンサート',
            place: '（2011 杉並区立勤労福祉会館）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20100503',
            event_date: '2010-05-03',
            title: 'トゥーランドット',
            place: '（2010 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20100424',
            event_date: '2010-04-24',
            title: 'トゥーランドット',
            place: '（2010 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20090425',
            event_date: '2009-04-25',
            title: 'リゴレット',
            place: '（2009 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20090418',
            event_date: '2009-04-18',
            title: 'リゴレット',
            place: '（2009 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20080426',
            event_date: '2008-04-26',
            title: 'コシ・ファン・トゥッテ',
            place: '（2008 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20080412',
            event_date: '2008-04-12',
            title: 'コシ・ファン・トゥッテ',
            place: '（2008 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20071007',
            event_date: '2007-10-07',
            title: 'ジョイフルコンサート',
            place: '（2007 杉並区立勤労福祉会館）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20061009',
            event_date: '2006-10-09',
            title: 'ジョイフルコンサート',
            place: '（2006 杉並区立勤労福祉会館）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20060415',
            event_date: '2006-04-15',
            title: '蝶々夫人',
            place: '（2006 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20060408',
            event_date: '2006-04-08',
            title: '蝶々夫人',
            place: '（2006 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20051022',
            event_date: '2005-10-22',
            title: 'ジョイフルコンサート',
            place: '（2005 杉並区立勤労福祉会館）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20050326a',
            event_date: '2005-03-26',
            title: 'カヴァレリア•ルスティカーナ',
            place: '（2005 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20050326b',
            event_date: '2005-03-26',
            title: 'パリアッチ',
            place: '（2005 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20050320a',
            event_date: '2005-03-20',
            title: 'カヴァレリア•ルスティカーナ',
            place: '（2005 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20050320b',
            event_date: '2005-03-20',
            title: 'パリアッチ',
            place: '（2005 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20041011',
            event_date: '2004-10-11',
            title: 'ジョイフルコンサート',
            place: '（2004 杉並区立勤労福祉会館）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20040425',
            event_date: '2004-04-25',
            title: '愛の妙薬',
            place: '（2004 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20040418',
            event_date: '2004-04-18',
            title: '愛の妙薬',
            place: '（2004 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20031018',
            event_date: '2003-10-18',
            title: 'ジョイフルコンサート',
            place: '（2003 ルネこだいら）',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '20030412',
            event_date: '2003-04-12',
            title: 'トスカ',
            place: '（2003 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20030406',
            event_date: '2003-04-06',
            title: 'トスカ',
            place: '（2003 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20020317',
            event_date: '2002-03-17',
            title: 'カルメン',
            place: '（2002 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '20010325',
            event_date: '2001-03-25',
            title: 'マリツァ伯爵夫人',
            place: '（2001 ルネこだいら）',
            event_type: EventType.Operetta,
            url: '#',
        },
        {
            event_id: '20000326',
            event_date: '2000-03-26',
            title: '椿姫',
            place: '（2000 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '19990322',
            event_date: '1999-03-22',
            title: 'ボエーム',
            place: '（1999 ルネこだいら）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '19970504',
            event_date: '1997-05-04',
            title: 'ミニヨン',
            place: '（1997 セシオン杉並）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '19960505',
            event_date: '1996-05-05',
            title: '愛の妙薬',
            place: '（1996 セシオン杉並）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '19950429',
            event_date: '1995-04-29',
            title: 'マリツァ伯爵夫人',
            place: '（1995 セシオン杉並）',
            event_type: EventType.Operetta,
            url: '#',
        },
        {
            event_id: '19940410',
            event_date: '1994-04-10',
            title: 'カルメン',
            place: '（1994 セシオン杉並）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '19930110',
            event_date: '1993-01-10',
            title: 'レギュラーコンサート',
            place: '（1993 杉並区立勤労福祉会館））',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '19920112',
            event_date: '1992-01-12',
            title: 'トスカ',
            place: '（1992 セシオン杉並）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '19910609',
            event_date: '1991-06-09',
            title: 'メリー・ウィドウ',
            place: '（1991 杉並区立勤労福祉会館）',
            event_type: EventType.Operetta,
            url: '#',
        },
        {
            event_id: '19910203',
            event_date: '1991-02-03',
            title: 'レギュラーコンサート',
            place: '（1991 杉並区立勤労福祉会館））',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '19901007',
            event_date: '1990-10-07',
            title: 'レギュラーコンサート',
            place: '（1990 杉並区立勤労福祉会館））',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '19900311',
            event_date: '1990-03-11',
            title: 'カヴァレリア•ルスティカーナ',
            place: '（1990 神田パンセホール）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '19891015',
            event_date: '1989-10-15',
            title: 'レギュラーコンサート',
            place: '（1989 杉並区立勤労福祉会館））',
            event_type: EventType.Concert,
            url: '#',
        },
        {
            event_id: '19890514',
            event_date: '1989-05-14',
            title: 'パリアッチ',
            place: '（1989 神田パンセホール）',
            event_type: EventType.Opera,
            url: '#',
        },
        {
            event_id: '19880429',
            event_date: '1988-04-29',
            title: '蝶々夫人',
            place: '（1988 杉並区立勤労福祉会館）',
            event_type: EventType.Opera,
            url: '#',
        }
    ]
}